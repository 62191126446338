<script>
  import {mapGetters, mapState} from 'vuex';
  import SharedPricing from '@/components/assignment_components/one_assignment/interactive_info/shared_components/SharedPricing';

  export default {
    extends: SharedPricing,
    computed: {
      ...mapGetters('AssignmentFlowStore', [
        'invoiceFinalData'
      ]),
      ...mapState('AssignmentFlowStore', {
        invoiceGenerationInProgress: ({invoiceGenerationInProgress}) => invoiceGenerationInProgress
      }),
      invoices() {
        return Object.keys(this.invoiceFinalData).length === 0
          ? []
          : [this.invoiceFinalData];
      },
      showPriceDescription() {
        return this.priceDescription && this.invoiceGenerationInProgress;
      },
      priceDescription() {
        return this.$gettext('Final invoice generation is in progress. Please load this page again in 5 minutes.');
      }
    }
  };
</script>
